<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { Location } from "vue-router";
import { useRoute } from "vue-router/composables";

import beacon from "@/lib/beacon";
import env from "../env";
import { AccountClient } from "../lib/API";
import { useAccessToken } from "../lib/useAccessToken";
import { getAccountsAppUrl, useAuth } from "../lib/useAuth";
import { useBreakpoints } from "../lib/useBreakpoints";
import { useTheme } from "../lib/useTheme";

type NavItem = {
  id?: string;
  disabled?: boolean;
  icon: string;
  text: string;
  href?: string;
  to?: Location;
};

type NavGroup = {
  groupHeader: string;
  items: NavItem[];
};

const { isAuthed, isBuyer, isProvider, isSuper, buyerId, providerId } =
  useAuth();
const { clearAccessToken } = useAccessToken();
const { dark, toggleTheme } = useTheme();

const open = ref(false);
const { mobile } = useBreakpoints();

function maybeToggleMenu() {
  if (mobile.value) {
    open.value = !open.value;
  }
}

// Sign up button items
const accountsAppUrl = getAccountsAppUrl();
function handleSignIn() {
  beacon.redirect(accountsAppUrl);
}

// Reset the menu state on route change
const route = useRoute();
watch(
  () => route.fullPath,
  () => {
    open.value = false;
  }
);

const providerNavItems = computed<NavGroup[]>(() => {
  return [];
});

const buyerNavItems = computed<NavGroup[]>(() => {
  if (!isBuyer.value) return [];
  return [
    {
      groupHeader: "Evaluate",
      items: [
        {
          icon: "location_city",
          to: { name: "portfolios" },
          text: "Portfolios",
        },
      ],
    },
  ];
});

const adminNavItems = computed<NavGroup[]>(() => {
  if (!isSuper.value) return [];
  return [
    {
      groupHeader: "Admin",
      items: [
        {
          icon: "post_add",
          to: { name: "portfolio-creator" },
          text: "Portfolio creator",
        },
      ],
    },
  ];
});

const navItems = computed<NavGroup[]>(() => {
  return [
    {
      groupHeader: "Home",
      items: [
        {
          to: { name: "dashboard" },
          icon: "dashboard",
          text: "Dashboard",
          id: "nav-dash-link",
        },
        {
          to: { name: "map" },
          icon: "map",
          text: "Map",
          id: "nav-map-link",
        },
      ],
    },
    ...adminNavItems.value,
    ...providerNavItems.value,
    ...buyerNavItems.value,
    {
      groupHeader: "Transact",
      items: [
        {
          to: { name: "marketplace" },
          icon: "store",
          text: "Marketplace",
          id: "nav-marketplace-link",
        },
        ...(isProvider.value && providerId.value
          ? [
              {
                to: {
                  name: "provider-profile",
                  params: { providerId: providerId.value },
                },
                icon: "group",
                text: "Provider Profile",
                id: "nav-org-link",
              },
            ]
          : []),
        ...(isBuyer.value && buyerId.value
          ? [
              {
                to: {
                  name: "buyer-profile",
                  params: { buyerId: buyerId.value },
                },
                icon: "group",
                text: "Buyer Profile",
                id: "nav-org-link",
              },
            ]
          : []),
      ],
    },
    {
      groupHeader: "Resources",
      items: [
        ...(isSuper.value
          ? [
              {
                to: { name: "policy-map" },
                icon: "public",
                text: "Policy Map",
              },
            ]
          : []),
        {
          to: { name: "updates" },
          icon: "new_releases",
          text: "Updates",
          id: "nav-updates-link",
        },
        {
          href: "https://blog.stationa.com",
          icon: "rss_feed",
          text: "Blog",
          id: "nav-blog-link",
        },
        {
          href: "https://help.stationa.com",
          icon: "help_center",
          text: "FAQs",
          id: "nav-faq-link",
        },
      ],
    },
  ];
});

const routerLinkProps = computed(() => ({
  activeClass: [
    "tw-pointer-events-none",
    "!tw-text-black",
    "dark:!tw-text-white",
    ...(isProvider.value
      ? ["tw-bg-provider-400", "dark:tw-bg-provider-500"]
      : ["tw-bg-buyer-400", "dark:tw-bg-buyer-500"]),
  ].join(" "),
}));

const logoutUrl = computed(
  () =>
    `${env.auth0BaseUrl}/oidc/logout?client_id=${env.authClientId}&post_logout_redirect_uri=${env.appBaseUrl}`
);
async function logout() {
  const client = new AccountClient();
  await client.logout();
  clearAccessToken();
  window.location.href = logoutUrl.value;
}
</script>

<template>
  <div
    class="tw-flex tw-flex-col tw-gap-4 tw-shadow-sm md:tw-gap-8 tw-p-4 tw-bg-zinc-100 dark:tw-bg-zinc-900"
  >
    <div
      class="tw-flex tw-justify-between tw-cursor-pointer md:tw-pointer-events-none"
      @click="maybeToggleMenu"
    >
      <sta-logo :full="mobile" :size="mobile ? 28 : 36" class="tw-mr-4" />
      <div class="tw-flex tw-gap-4">
        <t-btn
          color="green"
          title-case
          @click="handleSignIn"
          v-if="mobile && !isAuthed"
        >
          <v-icon left class="!tw-text-current">login</v-icon>
          <span>Sign in <span class="max-sm:tw-hidden">/ Sign up</span></span>
        </t-btn>
        <v-icon v-if="mobile">menu</v-icon>
      </div>
    </div>
    <div
      class="tw-flex tw-flex-col tw-gap-4 md:tw-gap-8 tw-overflow-y-auto tw-overscroll-contain tw-text-zinc-600 dark:tw-text-zinc-200"
      v-if="!mobile || open"
    >
      <div
        class="tw-flex tw-flex-col tw-gap-2"
        :key="groupIdx"
        v-for="(group, groupIdx) in navItems"
      >
        <h2 class="tw-subheading">{{ group.groupHeader }}</h2>
        <t-btn
          flat
          title-case
          :key="itemIdx"
          :id="item.id"
          :to="item.to"
          :router-link-props="routerLinkProps"
          :href="item.href"
          :target="item.href ? '_blank' : undefined"
          :title="item.text"
          :disabled="item.disabled"
          v-for="(item, itemIdx) in group.items"
        >
          <v-icon left class="!tw-ext-current">{{ item.icon }}</v-icon>
          <span>{{ item.text }}</span>
        </t-btn>
      </div>
      <hr />
      <div class="tw-flex tw-flex-col tw-gap-2">
        <h2 class="tw-subheading">Settings</h2>
        <t-btn flat title-case @click="toggleTheme">
          <v-icon left class="!tw-text-current">
            {{ dark ? "light_mode" : "dark_mode" }}
          </v-icon>
          <span>Change Theme</span>
        </t-btn>
        <template v-if="isAuthed">
          <t-btn
            flat
            title-case
            :router-link-props="routerLinkProps"
            :to="{ name: 'account' }"
          >
            <v-icon left class="!tw-text-current">account_circle</v-icon>
            <span>Account</span>
          </t-btn>
          <t-btn flat title-case @click="logout">
            <v-icon left class="!tw-text-current">logout</v-icon>
            <span>Log Out</span>
          </t-btn>
        </template>
        <template v-else>
          <t-btn color="green" title-case @click="handleSignIn">
            <v-icon left class="!tw-text-current">login</v-icon>
            <span>Sign in / Sign up</span>
          </t-btn>
        </template>
      </div>
      <template v-if="env.devMode">
        <hr />
        <div class="tw-flex tw-flex-col tw-gap-2">
          <h2 class="tw-subheading">Developer</h2>
          <t-btn
            flat
            title-case
            :router-link-props="routerLinkProps"
            :to="{ name: 'impersonate' }"
          >
            <v-icon left class="!tw-text-current">key</v-icon>
            <span>Impersonate</span>
          </t-btn>
          <t-btn
            flat
            title-case
            :router-link-props="routerLinkProps"
            :to="{ name: 'missing-module' }"
          >
            <v-icon left class="!tw-text-current">dangerous</v-icon>
            <span>Missing Module</span>
          </t-btn>
        </div>
      </template>
    </div>
  </div>
</template>
