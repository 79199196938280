import Vue from "vue";
import Router, { RouteConfig } from "vue-router";

import env from "./env";
import { collectIntent } from "./lib/beacon";
import { useToast } from "./lib/useToast";

Vue.use(Router);

const { pushToast } = useToast();

const routes: RouteConfig[] = [
  {
    name: "home",
    path: "/",
    redirect: "/dashboard",
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("@/views/Dashboard.vue"),
  },
  {
    name: "provider-profile",
    path: "/providers/:providerId",
    props: true,
    component: () => import("@/views/ProviderProfileView.vue"),
    meta: {
      requires: {
        isAuthed: true,
      },
    },
  },
  {
    name: "buyer-profile",
    path: "/buyers/:buyerId",
    props: true,
    component: () => import("@/views/BuyerProfile.vue"),
    meta: {
      requires: {
        isAuthed: true,
      },
    },
  },
  {
    name: "portfolio-creator",
    path: "/portfolio-creator",
    component: () => import("@/views/PortfolioCreator.vue"),
    meta: {
      requires: {
        // In the future, we may want to open up this page to buyers
        isSuper: true,
      },
    },
  },
  {
    name: "portfolios",
    path: "/portfolios",
    component: () => import("@/views/Portfolios.vue"),
  },
  {
    name: "portfolio",
    path: "/portfolios/:portfolioId",
    props: true,
    component: () => import("@/views/Portfolio.vue"),
  },
  {
    name: "map",
    path: "/map",
    component: () => import("@/views/Map.vue"),
  },
  {
    name: "policy-map",
    path: "/policies",
    component: () => import("@/views/PolicyMap.vue"),
  },
  {
    name: "not-found",
    path: "/not-found",
    component: () => import("@/views/NotFound.vue"),
  },
  {
    name: "marketplace",
    path: "/marketplace",
    component: () => import("@/views/Marketplace.vue"),
  },
  {
    name: "proposal-preview",
    path: "/proposals/preview",
    props: (route) => {
      return {
        rfpId: route.query.rfp_id,
      };
    },
    beforeEnter: (to, from, next) => {
      if (!to.query.rfp_id) {
        next("/not-found");
      } else {
        next();
      }
    },
    component: () => import("@/views/ProposalPreview.vue"),
    meta: {
      requires: {
        isAuthed: true,
      },
    },
  },
  {
    name: "rfp-builder",
    path: "/rfps/:rfpId/builder",
    props: true,
    component: () => import("@/views/RfpBuilder.vue"),
    meta: {
      requires: {
        isAuthed: true,
      },
    },
  },

  {
    name: "proposal-builder",
    path: "/proposals/builder",
    props: (route) => {
      return {
        rfpId: route.query.rfp_id,
      };
    },
    beforeEnter: (to, from, next) => {
      if (!to.query.rfp_id) {
        next("/not-found");
      } else {
        next();
      }
    },
    component: () => import("@/views/ProposalBuilder.vue"),
    meta: {
      requires: {
        isAuthed: true,
      },
    },
  },
  {
    name: "rfp-analysis",
    path: "/rfps/:rfpId/analysis",
    props: true,
    component: () => import("@/views/RfpAnalysis.vue"),
    meta: {
      requires: {
        isAuthed: true,
      },
    },
  },
  {
    name: "proposal-form-builder",
    path: "/proposals/builder/forms/:formId",
    props: (route) => {
      return {
        formId: route.params.formId,
        rfpId: route.query.rfp_id,
      };
    },
    beforeEnter: (to, from, next) => {
      if (!to.query.rfp_id) {
        next("/not-found");
      } else {
        next();
      }
    },
    component: () => import("@/views/FormBuilder.vue"),
    meta: {
      requires: {
        isAuthed: true,
      },
    },
  },
  {
    name: "proposal",
    path: "/proposals/:proposalId",
    props: true,
    component: () => import("@/views/Proposal.vue"),
    meta: {
      requires: {
        isAuthed: true,
      },
    },
  },
  {
    name: "proposal-form-preview",
    path: "/proposals/preview/forms/:formId",
    props: (route) => {
      return {
        formId: route.params.formId,
        rfpId: route.query.rfp_id,
      };
    },
    beforeEnter: (to, from, next) => {
      if (!to.query.rfp_id) {
        next("/not-found");
      } else {
        next();
      }
    },
    component: () => import("@/views/FormPreview.vue"),
    meta: {
      requires: {
        isAuthed: true,
      },
    },
  },
  {
    name: "proposal-form",
    path: "/proposals/:proposalId/forms/:formId",
    props: (route) => {
      return {
        formId: route.params.formId,
        proposalId: route.params.proposalId,
      };
    },
    component: () => import("@/views/Form.vue"),
    meta: {
      requires: {
        isAuthed: true,
      },
    },
  },
  {
    name: "rfp",
    path: "/rfps/:rfpId",
    props: true,
    component: () => import("@/views/RFP.vue"),
  },
  {
    name: "rfp-location",
    path: "/rfps/:rfpId/locations/:locationId",
    props: true,
    component: () => import("@/views/RfpLocationPage.vue"),
    meta: {
      requires: {
        isAuthed: true,
      },
    },
  },
  {
    name: "account",
    path: "/account",
    component: () => import("@/views/Account.vue"),
    meta: {
      requires: {
        isAuthed: true,
      },
    },
  },
  {
    name: "updates",
    path: "/updates",
    component: () => import("@/views/Updates.vue"),
  },
  {
    name: "catch-all",
    path: "*",
    redirect: "/not-found",
  },
];

if (env.devMode) {
  routes.push({
    name: "test",
    path: "/dev/test",
    component: () => import("@/views/Test.vue"),
  });
  routes.push({
    name: "impersonate",
    path: "/dev/impersonate",
    component: () => import("@/views/Impersonate.vue"),
  });
  routes.push({
    name: "missing-module",
    path: "/dev/missing-module",
    component: () => {
      throw new Error("Failed to fetch dynamically imported module");
    },
  });
}

const router = new Router({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (from.path !== to.path) {
      const newScrollPos = savedPosition || { x: 0, y: 0 };
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(newScrollPos);
        }, 0);
      });
    }
  },
});

router.afterEach((to, from) => {
  collectIntent(from.path, "transition", {
    target: to.path,
  });

  // We need to do this in order to ensure HubSpot's chatflow targeting re-evaluates on each route
  // navigation, since it doesn't seem to support the History API
  // @ts-expect-error Global scope pollution
  if (window.HubSpotConversations && window.HubSpotConversations.widget) {
    // @ts-expect-error Global scope pollution
    window.HubSpotConversations.widget.refresh();
  }
});

router.onError((err) => {
  // Just being extra conservative
  if (err.message.includes("dynamically imported module")) {
    pushToast(
      {
        level: "error",
        message:
          "A new version of the app is available! Hit 'Refresh' to reload!",
        persistent: true,
        action: {
          label: "Refresh",
          fn: async () => window.location.reload(),
        },
      },
      "missing-module"
    );
    return;
  }
  throw err;
});

export default router;
