import { computed, ref, watch } from "vue";
import { toRef, MaybeRefOrGetter } from "@vueuse/core";
import router from "../router";
import { useAsyncState } from "@vueuse/core";
import { getClient } from "./client";

import { Buyer } from "@/lib/OrgTypes";
import { BuyersClient } from "@/lib/BuyerAPI";
import { requiredFields } from "@/lib/BuyerFields";

export function useBuyerProfile(buyerId: MaybeRefOrGetter<string | null>) {
  const reactiveBuyerId = toRef(buyerId);

  const buyer = ref<Buyer | null>(null);
  const initialLoadComplete = ref(false);
  const buyerLoading = ref(true);
  const buyerClient = new BuyersClient();

  const reloadBuyer = async () => {
    buyerLoading.value = true;
    try {
      if (!reactiveBuyerId.value) {
        buyer.value = null;
        return;
      }
      const maybeBuyer = await buyerClient.getBuyer(reactiveBuyerId.value);
      if (maybeBuyer) {
        buyer.value = maybeBuyer;
      }
      return buyer;
    } catch (e) {
      router.push({ name: "not-found" }).catch(() => {});
    } finally {
      buyerLoading.value = false;
      initialLoadComplete.value = true;
    }
  };

  watch(reactiveBuyerId, reloadBuyer, { immediate: true });

  return {
    buyer,
    initialLoadComplete,
    reloadBuyer,
    buyerLoading,
  };
}

export function isNonEmptyValue(value: any) {
  // This method controls whether or not we want to show that this field is
  // complete/has a valid answer to the user
  if (value === null) return false;

  // Since empty arrays are truthy, we need to check that it is not empty
  if (Array.isArray(value) && value.length === 0) return false;

  // We want to allow a user to input 0
  if (typeof value === "number" || typeof value === "boolean") return true;

  // This allows for attachments to be marked as complete
  return !!value;
}

export function useBuyerCompleteness(buyerId: MaybeRefOrGetter<string | null>) {
  const { buyer, reloadBuyer, buyerLoading, initialLoadComplete } =
    useBuyerProfile(buyerId);

  const totalRequired = computed(() => {
    return requiredFields.length;
  });

  const totalRequiredComplete = computed(() => {
    return requiredFields.filter((key: string) =>
      isNonEmptyValue(buyer.value![key])
    ).length;
  });

  function getPercentComplete() {
    if (!buyer.value) {
      return 0;
    }
    return (totalRequiredComplete.value / totalRequired.value) * 100;
  }

  const percentComplete = computed(() => {
    if (buyer.value) {
      const rawPercentComplete = getPercentComplete();
      if (rawPercentComplete) {
        return Math.round(rawPercentComplete);
      }
    }
    return 0;
  });

  const isComplete = computed(() => percentComplete.value === 100);

  const progressChipStatus = computed(() => {
    if (!percentComplete.value) {
      return "not-started";
    }
    if (percentComplete.value === 100) {
      return "completed";
    } else if (percentComplete.value < 100) {
      return "in-progress";
    } else {
      return "not-started";
    }
  });

  return {
    buyer,
    reloadBuyer,
    buyerLoading,
    initialLoadComplete,
    percentComplete,
    isComplete,
    getPercentComplete,
    totalRequired,
    totalRequiredComplete,
    progressChipStatus,
  };
}

export function useBuyers() {
  const client = getClient();

  const {
    state: buyers,
    isReady: ready,
    isLoading: loading,
    error: error,
    execute: reloadBuyers,
  } = useAsyncState(
    async () => {
      return await client.get<Buyer[]>(`/buyers`).then((t) => t.data);
    },
    undefined,
    { resetOnExecute: false }
  );

  return {
    buyers,
    ready,
    loading,
    error,
    reloadBuyers,
  };
}
